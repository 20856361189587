<template>
  <div class="container-xl px-0 px-sm-3">
    <!-- Encabezado -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Historial de pagos</h2>
      <div v-if="!$apolloData.queries.pagos.loading" class="col-auto">
        <mdb-btn
          color="primario"
          icon="plus"
          class="my-sm-0 px-3"
          :disabled="botonDeshabilitado"
          @click="mostrarModalPago = true"
        >
          Nuevo pago
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.pagos.loading"
      clase-adicional="vc-75vh"
    />
    <div v-else class="mb-page">
      <!-- Filtros -->
      <div
        class="row align-items-center justify-content-center
          justify-content-md-start my-1 mx-0"
      >
        <mdb-btn
          flat
          dark-waves
          icon="list"
          class="btn-bordeado my-sm-0 py-2 px-3"
          @click="
            pagoActualizar();
            buscarPor.estatus = '';
          "
        >
          Todos
        </mdb-btn>
        <div class="col-auto pr-0">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select-pago"
              v-model="buscarPor.estatus"
              class="custom-select"
              @change="pagoActualizar('estatus')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in EstatusPago"
                :key="`estatusPago-${key}`"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select-pago"
              :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
      </div>
      <!-- Tabla -->
      <ul data-table="sticky" class="table">
        <li class="encabezado row align-items-end">
          <div class="col-2 col-xl-1 d-none d-sm-block">Fecha</div>
          <div class="col d-none d-md-block">Medio / Monto</div>
          <div class="col">
            <span class="d-md-none">Detalles</span>
            <span class=" d-none d-md-inline">Referencia</span>
          </div>
          <div class="col d-none d-xl-block">Observación</div>
          <div class="col col-xl-2 d-none d-sm-block">Estatus</div>
          <div class="col-auto pr-0 acciones">Acción</div>
        </li>
        <li
          v-for="(pago, ipago) in pagos"
          :key="ipago"
          class="contenido normal row align-items-center"
        >
          <div class="col-2 col-xl-1 d-none d-sm-block fecha">
            <span class="d-block">
              {{ formatoFecha(pago.fecha.formatted).ddmm }}
            </span>
            {{ formatoFecha(pago.fecha.formatted).yyyy }}
          </div>
          <div class="col d-none d-md-block">
            {{ pago.medio.nombre }}
            <p class="monto">
              ${{ pago.monto }}
            </p>
          </div>
          <div class="col pr-0">
            <p class="my-2 d-sm-none">
              Fecha: {{ formatoFecha(pago.fecha.formatted).ddmmyyyy }}
            </p>
            <p class="my-2 d-md-none">
              Medio: {{ pago.medio.nombre }}
              <span class="d-block">
                Monto: ${{ pago.monto }}
              </span>
            </p>
            <span class="d-md-none">Referencia:</span>
            {{ pago.referencia }}
            <p v-if="pago.observacionCliente" class="mt-md-2 mb-0 d-xl-none">
              Observación: {{ pago.observacionCliente }}
            </p>
            <p class="my-2 d-sm-none">
              Estatus:
              <span
                v-if="pago.estatus"
                :class="['ml-1 estatus', EstatusPagoIconoColor[pago.estatus]]"
              >
                <font-awesome-icon
                  :icon="EstatusPagoIconos[pago.estatus]"
                  size="lg"
                />
                {{ EstatusPago[pago.estatus] }}
              </span>
              <span v-else class="ml-1 text-muted font-italic">
                Sin especificar
              </span>
            </p>
          </div>
          <div
            :class="['col d-none d-xl-block',
            {'text-muted font-italic': !pago.observacionCliente}
            ]"
          >
            {{ pago.observacionCliente ? pago.observacionCliente : 'Sin especificar' }}
          </div>
          <div class="col col-xl-2 d-none d-sm-block">
            <p
              v-if="pago.estatus"
              :class="['estatus', EstatusPagoIconoColor[pago.estatus]]"
            >
              <font-awesome-icon
                :icon="EstatusPagoIconos[pago.estatus]"
                size="lg"
              />
              {{ EstatusPago[pago.estatus] }}
            </p>
            <span v-else class="text-muted font-italic">
              Sin especificar
            </span>
          </div>
          <div
            :class="['col-auto pr-0 acciones',
              {'cursor-not-allowed': pago.estatus !== 'PorConfirmar'}
            ]"
            :title="pago.estatus !== 'PorConfirmar'
              ? 'Solo se permite cuando el pago tiene un estatus Por confirmar' : ''"
          >
            <mdb-btn
              flat
              dark-waves
              icon="trash"
              :disabled="botonDeshabilitado || pago.estatus !== 'PorConfirmar'"
              icon-class="texto-peligro"
              class="m-0 ml-2 ml-md-0 py-3 px-2 btn-bordeadoOnHover"
              title="Eliminar pago"
              @click="mostrarConfirmacionEliminar = true;
                pagoIdEliminar = pago.id;
                obtenerComprobantesEliminar();"
            />
            <mdb-btn
              flat
              dark-waves
              icon="pen"
              :disabled="botonDeshabilitado || pago.estatus !== 'PorConfirmar'"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Editar pago"
              @click="pagoAEditar(pago)"
            />
            <mdb-btn
              flat
              dark-waves
              icon="file-invoice-dollar"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Ver comprobantes"
              @click="obtenerComprobantes(pago.id, pago.estatus)"
            />
          </div>
        </li>
        <li v-if="!(pagos && pagos.length)" class="no-items">
          No hay pagos registrados
        </li>
      </ul>
    </div>
    <FormularioPago
      :mostrarModalPago="mostrarModalPago"
      :es-editar="esEditar"
      :titulo="esEditar ? 'Datos del pago a editar' : 'Datos del nuevo pago'"
      :pagoAsignar="pagoAsignar && pagoAsignar"
      :clienteEspecificado="clienteId ? { id: clienteId } : {}"
      @crear="pagoCrear($event)"
      @cerrar="
        mostrarModalPago = false;
        botonDeshabilitado = false;
      "
      @editar="esEditar = $event"
      @editarPago="pagoEditar($event)"
      @clienteError="errorCliente"
      @alertaMsg="comprobanteError($event)"
      @alerta="alertaMensaje = $event"
    />
    <AlertaMensaje :alerta-mensaje="alertaMensaje" @cerrar="alertaMensaje.contenido = ''" />
    <!-- Comprobantes del Pago -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarModalComprobante"
      @close="cerrarModalComprobante"
    >
      <mdb-modal-header>
        <mdb-modal-title> Comprobantes de pago </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <h5 class="mb-3">Fotos del comprobante</h5>
        <div v-if="cargandoComprobantes">
          <CargandoVista
            v-if="cargandoComprobantes"
            clase-adicional="vc-50vh"
          />
        </div>
        <div v-else>
          <ul
            v-if="comprobante.fotos && comprobante.fotos.length"
            class="listado-fotos"
          >
            <li
              v-for="(foto, iFoto) in comprobante.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto}`"
                  :alt="`Comprobante del pago: Imagen nro ${iFoto + 1}`"
                  @click="abrirGaleriaEn(iFoto)"
                />
              </div>
              <div v-show="mostrarCheckboxFoto">
              <label
                class="boton-eliminar checkbox-grande"
                title="Seleccionar las imagenes del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="fotos-eliminar"
                  v-model="comprobantesEliminar"
                  :value="foto"
                />
                <span class="checkbox" />
              </label>
              </div>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay comprobantes adjuntos para mostrar
          </span>
          <h5 class="my-3">Documentos PDF</h5>
          <ul
            v-if="comprobante.pdf && comprobante.pdf.length"
            class="lista-archivos"
          >
            <li
              v-for="(urlArchivo, pdfIndex) in comprobante.pdf"
              :key="pdfIndex"
              class="archivo-contenedor"
            >
              <a
                :href="`${endpointPublicBackend}${urlArchivo}`"
                target="_blank"
                rel="noopener noreferrer"
                class="archivo"
              >
                <pdf :src="`${endpointPublicBackend}${urlArchivo}`"> </pdf>
              </a>
              <div v-show="mostrarCheckboxFoto">
              <label
                class="boton-eliminar checkbox-grande"
                title="Seleccionar archivos del comprobante a eliminar"
              >
                <input
                  type="checkbox"
                  name="pdf-checkboxes"
                  v-model="comprobantesEliminar"
                  :value="urlArchivo"
                />
                <span class="checkbox" />
              </label>
              </div>
            </li>
          </ul>
          <span v-else class="text-muted user-select-none">
            No hay comprobantes en fomato pdf adjuntos para mostrar
          </span>
          <div
            class="row justify-content-center align-items-center my-3"
            v-if="comprobante.pdf.length || comprobante.fotos.length"
          >
           <mdb-btn
              v-show="mostrarCheckboxFoto"
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'check-square'"
              :icon-class="[{'texto-terciario': comprobantesEliminar.length},
              {'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="seleccionarTodo"
            >
              {{ tituloBotonSeleccionarTodo }}
            </mdb-btn>
            <mdb-btn
              v-show="mostrarCheckboxFoto"
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
              :icon-class="[{'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado || !comprobantesEliminar.length"
              class="btn-bordeado col-auto px-3 mt-3"
              @click="
                mostrarConfirmacionEliminar = true;
                esEliminarComprobantes = true;
              "
            >
              Eliminar comprobantes
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <LightBox
      v-if="galeriaFotosComprobante && galeriaFotosComprobante.length"
      ref="lightbox"
      :media="galeriaFotosComprobante"
      :show-light-box="false"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarConfirmacionEliminar"
      :mensaje="msgAlerta"
      :cancelarMensaje="cancelarMensajeAlerta"
      @cerrar="
        mostrarConfirmacionEliminar = $event;
        pagoIdEliminar = '';
        tieneComprobantes = false;
      "
      @eliminar="metodoEliminar"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import CargandoVista from '@/components/CargandoVista.vue'
import { EstatusPago, EstatusPagoIconos, EstatusPagoIconoColor, regExpPdf } from '@/constantes/pagos.js'
import { formatearFecha } from '@/funciones/funciones.js'
import { leerPersonaId } from '@/utils/datosToken.js'
import gql from 'graphql-tag'
import mediosGql from '@/graphql/medios.gql'
import FormularioPago from '@/components/FormularioPago.vue'
import pagoCrearGql from '@/graphql/pagoCrear.gql'
import pagoEditarGql from '@/graphql/pagoEditar.gql'
import pagoEliminarGql from '@/graphql/pagoEliminar.gql'
import obtenerFotosGql from '@/graphql/obtenerFotos.gql'
import pagosFilterGql from '@/graphql/pagoFilter.gql'
import fotoEliminarGql from '@/graphql/fotoEliminar.gql'
import clientePagosGql from '@/graphql/clientePagos.gql'

import { endpointPublicBackend } from '@/constantes/paquetes.js'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import LightBox from 'vue-image-lightbox'
import pdf from 'vue-pdf'
export default {
  name: 'Pagos',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    FormularioPago,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    LightBox,
    pdf
  },
  data () {
    return {
      // Constantes
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      // Otros
      buscarPor: { estatus: '' },
      pagos: [],
      botonDeshabilitado: false,
      mostrarModalPago: false,
      mostrarCheckboxFoto: false,
      mostrarConfirmacionEliminar: false,
      mostrarModalComprobante: false,
      comprobantesEliminar: [],
      esEditar: false,
      cargandoPagos: false,
      cargandoComprobantes: false,
      tieneComprobantes: false,
      esEliminarComprobantes: false,
      pagoIdComprobante: '',
      alertaMensaje: { contenido: '' },
      pagoIdEliminar: '',
      comprobante: {
        fotos: [],
        pdf: []
      },
      galeriaFotosComprobante: [],
      pagoAsignar: {
        medio: '',
        fecha: {},
        monto: '',
        referencia: ''
      },
      endpointPublicBackend,
      // Datos del cliente
      clienteId: ''
    }
  },
  mounted () {
    this.clienteId = leerPersonaId()
  },
  methods: {
    // Dar formato a fecha
    formatoFecha (fecha) {
      return formatearFecha(fecha)
    },
    abrirGaleriaEn (foto) {
      this.$refs.lightbox.showImage(foto)
    },
    pagoCrear ({ pago, comprobante, medioId }) {
      this.botonDeshabilitado = true
      const { cliente, ...restPago } = pago
      delete restPago.__typename
      delete restPago.fecha.__typename
      delete cliente.__typename
      const { id: idMedio, nombre: nombreDelMedio, descripcion } = medioId
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoCrearGql}
          `,
          variables: {
            pago: restPago,
            medioId: idMedio,
            personaId: cliente.id,
            comprobante: { foto: comprobante }
          },
          update: (store, { data: { pagoCrear } }) => {
            const data = store.readQuery({
              query: clientePagosGql,
              variables: {
                filter: {
                  id: this.clienteId
                }
              }
            })
            const pagoAgregar = {
              ...restPago,
              ...pagoCrear,
              medio: {},
              cliente: {}
            }
            pagoAgregar.__typename = 'Pago'
            pagoAgregar.medio.nombre = nombreDelMedio
            pagoAgregar.medio.id = idMedio
            pagoAgregar.medio.descripcion = descripcion
            pagoAgregar.medio.__typename = 'Medio'
            pagoAgregar.fecha.__typename = '_Neo4jDateTime'
            data.Cliente[0].pagos = [...data.Cliente[0].pagos, pagoAgregar]

            store.writeQuery({
              query: clientePagosGql,
              variables: {
                filter: {
                  id: this.clienteId
                }
              },
              data
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            pagoCrear: {
              __typename: 'Pago',
              id: '-1',
              monto: restPago.monto,
              estatus: restPago.estatus,
              medio: {
                nombre: nombreDelMedio,
                __typename: 'Medio'
              },
              referencia: restPago.referencia,
              observacionCliente: restPago.observacionCliente.length ? restPago.observacionCliente : '',
              fecha: {
                formatted: restPago.fecha.formatted,
                __typename: '_Neo4jDateTime'
              }
            }
          }
        })
        .then(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Pago agregado correctamente',
            tipo: 'correcto'
          }
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error agregando el pago',
            tipo: 'error'
          }
        })
    },
    comprobanteError ({ contenido, tipo }) {
      if (!contenido || !tipo) return
      this.alertaMensaje = { contenido, tipo }
    },
    seleccionarTodo () {
      const pdfCantidad = this.comprobante.pdf.length
      const fotosCantidad = this.comprobante.fotos.length
      const todosLosElementosSeleccionados = this.comprobantesEliminar.length ===
        (pdfCantidad + fotosCantidad)
      if (todosLosElementosSeleccionados) {
        this.comprobantesEliminar = []
        return
      }
      const pdfAEliminar = this.comprobante.pdf.map(pdf => pdf)
      const fotosAEliminar = this.comprobante.fotos.map(foto => foto)
      this.comprobantesEliminar = pdfAEliminar.concat(fotosAEliminar)
    },
    metodoEliminar () {
      this.esEliminarComprobantes
        ? this.eliminarComprobantes()
        : this.pagoEliminar()
    },
    cerrarModalComprobante () {
      this.mostrarModalComprobante = false
      this.comprobantesEliminar = []
      this.comprobante.pdf = []
      this.comprobante.fotos = []
      this.galeriaFotosComprobante = []
      this.pagoIdComprobante = ''
      this.esEliminarComprobantes = false
    },
    eliminarComprobantes () {
      if (!this.comprobantesEliminar.length || !this.pagoIdComprobante) {
        return (this.alertaMensaje = {
          contenido: 'Debes seleccionar al menos un comprobante',
          tipo: 'advertencia'
        })
      }
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: gql`
            ${fotoEliminarGql}
          `,
          variables: {
            grafoId: this.pagoIdComprobante,
            url: this.comprobantesEliminar,
            entidadEliminar: 'Pago'
          }
        })
        .then(({ data: { fotoEliminar } }) => {
          switch (fotoEliminar.codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: `${this.comprobantesEliminar.length > 1 ? 'Comprobantes' : 'Comprobante'} eliminado correctamente`,
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.alertaMensaje = {
                contenido: 'No se pudo eliminar los comprobantes',
                tipo: 'error'
              }
              break
            default:
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando los comprobantes',
                tipo: 'error'
              }
              break
          }
          this.establecerValoresEliminar()
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido:
              'Ha ocurrido un error inesperado eliminando los comprobantes',
            tipo: 'error'
          }
          this.establecerValoresEliminar()
        })
    },
    obtenerComprobantesEliminar () {
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId: this.pagoIdEliminar
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: 'network-only'
        })
        .then(({ data: { obtenerFotos } }) => {
          this.tieneComprobantes = obtenerFotos.length > 0
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error consultando los comprobantes',
            tipo: 'error'
          }
        })
    },
    obtenerComprobantes (grafoId, pagoEstatus) {
      this.mostrarCheckboxFoto = Object.keys(EstatusPago)[0] === pagoEstatus
      this.cargandoComprobantes = true
      this.mostrarModalComprobante = true
      this.pagoIdComprobante = grafoId
      this.$apollo
        .query({
          query: gql`
            ${obtenerFotosGql}
          `,
          variables: {
            grafoId
          },
          update: (data) => data.obtenerFotos,
          fetchPolicy: 'network-only'
        })
        .then(({ data: { obtenerFotos } }) => {
          const archivosUrl = obtenerFotos.map((archivo) => archivo.url)
          this.comprobante.pdf = archivosUrl.filter((url) =>
            regExpPdf.test(url)
          )
          this.comprobante.fotos = archivosUrl.filter(
            (url) => !regExpPdf.test(url)
          )
          if (this.comprobante.fotos.length) {
            const endpoint = this.endpointPublicBackend
            this.galeriaFotosComprobante = this.comprobante.fotos.map(
              (foto) => ({
                thumb: `${endpoint}${foto}`,
                src: `${endpoint}${foto}`
              })
            )
          } else this.galeriaFotosComprobante = []
          this.cargandoComprobantes = false
        })
        .catch(() => {
          this.cargandoComprobantes = false
          this.mostrarModalComprobante = false
        })
    },
    pagoEliminar () {
      if (this.tieneComprobantes) {
        return (this.alertaMensaje = {
          contenido: 'Para eliminar el pago debes remover los comprobantes adjuntos',
          tipo: 'Advertencia'
        })
      }
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoEliminarGql}
          `,
          variables: {
            pagoId: this.pagoIdEliminar
          },
          update: (
            store,
            {
              data: {
                pagoEliminar: { codigo }
              }
            }
          ) => {
            switch (codigo) {
              case 'Correcto':
                this.alertaMensaje = {
                  contenido: 'Pago eliminado correctamente',
                  tipo: 'correcto'
                }
                break
              case 'Fallido':
                this.alertaMensaje = {
                  contenido: 'No se pudo eliminar el pago',
                  tipo: 'error'
                }
                this.establecerValoresEliminar()
                return
              default:
                this.alertaMensaje = {
                  contenido: 'Ocurrió un error inesperado',
                  tipo: 'error'
                }
                this.establecerValoresEliminar()
                return
            }
            const data = store.readQuery({
              query: gql`
                ${clientePagosGql}
              `,
              variables: {
                filter: {
                  id: this.clienteId
                }
              }
            })
            data.Cliente[0].pagos = data.Cliente[0]
              .pagos.filter(({ id }) => id !== this.pagoIdEliminar)
            store.writeQuery({
              query: gql`
                ${clientePagosGql}
              `,
              variables: {
                filter: {
                  id: this.clienteId
                }
              },
              data
            })
            this.establecerValoresEliminar()
          },
          optimisticResponse: {
            __typename: 'Mutation',
            pagoEliminar: {
              codigo: 'Correcto',
              __typename: 'Resultado'
            }
          }
        })
        .catch(() => {
          this.establecerValoresEliminar()
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error eliminando el pago',
            tipo: 'error'
          }
        })
    },
    pagoAEditar (pagoSeleccionado) {
      this.botonDeshabilitado = true
      this.pagoAsignar = JSON.parse(JSON.stringify(pagoSeleccionado))
      this.esEditar = true
      this.mostrarModalPago = true
    },
    pagoEditar ({ pago, medioId }) {
      this.botonDeshabilitado = true
      pago.monto = Number(pago.monto)
      const pagoVariable = JSON.parse(JSON.stringify(pago))
      const { id: idMedio, nombre: nombreDelMedio, descripcion } = medioId
      this.$apollo
        .mutate({
          mutation: gql`
            ${pagoEditarGql}
          `,
          variables: {
            pago: pagoVariable,
            medioId: idMedio
          },
          update: (store) => {
            const data = store.readQuery({
              query: clientePagosGql,
              variables: {
                filter: {
                  id: this.clienteId
                }
              }
            })
            data.Cliente[0].pagos.filter(({ id }) => id === pagoVariable.id).map(
              (pagoEditar) => {
                if (!pagoEditar.medio) {
                  pagoEditar.medio = {}
                  pagoEditar.medio.__typename = 'Medio'
                }
                pagoEditar.monto = pagoVariable.monto
                pagoEditar.medio.nombre = nombreDelMedio
                pagoEditar.medio.id = idMedio
                pagoEditar.medio.descripcion = descripcion
                pagoEditar.observacionCliente = pagoVariable.observacionCliente?.length ? pagoVariable.observacionCliente : ''
                pagoEditar.referencia = pagoVariable.referencia
                pagoEditar.fecha.formatted = pagoVariable.fecha.formatted
              }
            )
            store.writeQuery({
              query: clientePagosGql,
              variables: {
                filter: {
                  id: this.clienteId
                }
              },
              data
            })
          },
          optimisticResponse: {
            __typename: 'Mutation',
            pagoEditar: {
              __typename: 'Resultado',
              codigo: 'Correcto'
            }
          }
        })
        .then(
          ({
            data: {
              pagoEditar: { codigo }
            }
          }) => {
            switch (codigo) {
              case 'Correcto':
                this.alertaMensaje = {
                  contenido: 'Pago actualizado correctamente',
                  tipo: 'correcto'
                }
                break
              case 'Fallido':
                this.alertaMensaje = {
                  contenido: 'No se pudo actualizar el pago',
                  tipo: 'error'
                }
                break
              default:
                this.alertaMensaje = {
                  contenido: 'Ocurrió un error inesperado',
                  tipo: 'error'
                }
                break
            }
            this.botonDeshabilitado = false
            this.limpiarCamposForm()
          }
        )
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ha ocurrido un error editando el pago',
            tipo: 'error'
          }
        })
    },
    limpiarCamposForm () {
      this.botonDeshabilitado = false
      this.pagoIdEliminar = ''
      this.pagoAsignar = { medio: '', fecha: {}, cliente: {} }
      this.comprobante.pdf = []
      this.comprobante.fotos = []
      this.galeriaFotosComprobante = []
      this.pagoIdComprobante = ''
    },
    errorCliente () {
      return (this.alertaMensaje = {
        contenido: 'El cliente es requerido para crear el pago',
        tipo: 'error'
      })
    },
    establecerValoresEliminar () {
      this.botonDeshabilitado = false
      this.mostrarConfirmacionEliminar = false
      this.pagoId = ''
      this.comprobante.pdf = []
      this.comprobante.fotos = []
      this.comprobantesEliminar = []
      this.mostrarModalComprobante = false
      this.galeriaFotosComprobante = []
      this.pagoIdComprobante = ''
      this.esEliminarComprobantes = false
      this.tieneComprobantes = false
    },
    pagoActualizar (estatus) {
      this.cargandoPagos = true
      this.botonDeshabilitado = true
      const filter = {
        cliente: {}
      }
      // eslint-disable-next-line
      estatus && this.buscarPor.estatus !== ''
        ? (filter.estatus = this.buscarPor.estatus)
        : ''
      filter.cliente.id = this.clienteId
      this.$apollo
        .watchQuery({
          query: gql`
            ${pagosFilterGql}
          `,
          update: (data) => data.Pago,
          fetchPolicy: 'cache-and-network',
          variables: {
            filter
          }
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Pago) {
              this.pagos = data.Pago
            }
            this.botonDeshabilitado = false
            this.cargandoPagos = false
          },
          error: () => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error actualizando los pagos',
              tipo: 'error'
            }
            this.botonDeshabilitado = false
            this.cargandoPagos = false
          }
        })
    }
  },
  computed: {
    modalPago () {
      return this.esEditar
        ? {
          titulo: 'Datos del pago a editar',
          botonPrincipal: {
            icono: 'sync',
            texto: 'Actualizar'
          }
        }
        : {
          titulo: 'Nuevo pago',
          botonPrincipal: {
            icono: 'check',
            texto: 'Agregar'
          }
        }
    },
    cargandoPagosTemplate () {
      return !!(this.$apolloData.queries.pagos.loading || this.cargandoPagos)
    },
    msgAlerta () {
      return this.esEliminarComprobantes
        ? `¿Estas seguro en eliminar ${
            this.comprobantesEliminar.length > 1
              ? 'los comprobantes seleccionados?'
              : 'el comprobante seleccionado?'
          }`
        : 'Recibimos la orden de eliminar este pago, es una acción que no puede revertirse'
    },
    cancelarMensajeAlerta () {
      return !!this.esEliminarComprobantes
    },
    tituloBotonSeleccionarTodo () {
      const pdfDisponibles = this.comprobante.pdf.length
      const fotosDisponibles = this.comprobante.fotos.length
      const totalComprobantes = pdfDisponibles + fotosDisponibles
      return this.comprobantesEliminar.length === totalComprobantes
        ? 'Deseleccionar todo'
        : 'Seleccionar todo'
    }
  },
  apollo: {
    medios () {
      return {
        query: mediosGql,
        update: (data) => data.Medio,
        fetchPolicy: 'cache-and-network'
      }
    },
    pagos () {
      return {
        query: clientePagosGql,
        variables: {
          filter: {
            id: leerPersonaId()
          }
        },
        update: (data) => data.Cliente[0].pagos,
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.col-auto.acciones {
  width: 64px;
  @media screen and (min-width: 768px){width: 137px;}
}
.contenido {
  & > .fecha {
    @media screen and (min-width: 768px){font-size: 1rem;}
  }
  .monto,
  .estatus {
    font-weight: 500;
    margin-bottom: 0;
  }
  .monto {font-size: 1rem;}
}
.campo {
  margin-bottom: 1rem;
  &.invalido > .mensaje-invalido.prepend {left: 47px;}
}
.blockquote {
  font-size: .8rem;
  .bq-title {font-size: 1rem;}
}
</style>
