<template>
  <section>
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="mostrarModalPago"
      @close="cerrarModal()"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ titulo }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="contenedor-formulario">
          <div class="descripcion">
            <transition
              mode="out-in"
              name="transition-entre-mensajes"
            >
              <div
                v-if="medio && medio.descripcion"
                key="detalles"
                class="mensaje"
              >
                <header>Detalles:</header>
                <pre>{{ medio.descripcion }}</pre>
              </div>
              <div
                v-else
                key="noMedio"
                class="no-medio"
              >
                <font-awesome-icon icon="info-circle" />
                <p class="mb-0">
                  Selecciona un medio de pago para mostrar más información
                </p>
              </div>
            </transition>
          </div>
          <form
            class="formulario row justify-content-center mt-lg-n2"
            @submit.prevent
          >
            <div
              :class="['col-12 col-sm-5',
                { 'campo': validarValores.fecha
                  && validarValores.fecha.length },
                { 'valido': validarValores.fecha === 'valido' },
                { 'invalido': validarValores.fecha === 'invalido' },
              ]"
            >
              <mdb-input
                v-model="pago.fecha.formatted"
                type="date"
                label="Fecha pago"
                outline
                class="my-2"
              />
              <p
                v-if="validarValores.fecha === 'invalido'"
                class="mensaje-invalido date"
              >
                Indica la fecha
              </p>
            </div>
            <div
              :class="['col-12 col-sm-7 pl-sm-0',
                { 'campo': validarValores.medio && validarValores.medio.length },
                { 'valido': validarValores.medio === 'valido' },
                { 'invalido': validarValores.medio === 'invalido' },
                {'cursor-not-allowed campo invalido mb-0': !(medios && medios.length)}
              ]"
              title="Debes agregar al menos 1 método de pago"
            >
              <div
                :class="['md-form md-outline outline-select my-2',
                  {'no-pointer-events': !(medios && medios.length)}
                ]"
              >
                <select
                  id="medio-select"
                  v-model="medio"
                  class="custom-select"
                >
                <option class="d-none" disabled :value="{}">Seleccione</option>
                  <option
                    v-for="(value, key) in medios"
                    :key="`medioPago-${key}`"
                    :value="value"
                  >
                    {{ value.nombre }}
                  </option>
                </select>
                <small
                  v-if="!(medios && medios.length)"
                  class="form-text text-muted user-select-none"
                >
                  <font-awesome-icon
                    icon="exclamation-triangle"
                    class="text-warning"
                  />
                  Debes registrar al menos 1 medio de pago
                </small>
                <label
                  for="medio-select"
                  :class="Object.entries(medio).length ? 'label-active' : 'label-inactive'"
                >
                  Medio de pago
                </label>
              </div>
              <p
                v-if="validarValores.medio === 'invalido' && (medios && medios.length)"
                class="mensaje-invalido"
              >
                Selecciona el medio de pago
              </p>
            </div>
            <div
              :class="['col-12',
                { 'campo': validarValores.monto && validarValores.monto.length },
                { 'valido': validarValores.monto === 'valido' },
                { 'invalido': validarValores.monto === 'invalido' },
              ]"
            >
              <mdb-input
                v-model.number="pago.monto"
                type="number"
                :min="1"
                label="Monto"
                class="my-2 dollar-addon"
                outline
              >
                <span
                  slot="prepend"
                  class="input-group-text md-addon user-select-none"
                  title="Dólares"
                >
                  $
                </span>
              </mdb-input>
              <p
                v-if="validarValores.monto === 'invalido'"
                class="mensaje-invalido prepend"
              >
                Indica el monto
              </p>
            </div>
            <div
              :class="['col-12',
                { 'campo': validarValores.referencia && validarValores.referencia.length },
                { 'valido': validarValores.referencia === 'valido' },
                { 'invalido': validarValores.referencia === 'invalido' },
              ]"
            >
              <mdb-input
                v-model.trim="pago.referencia"
                type="text"
                label="Referencial del pago"
                class="my-2"
                outline
              />
                <p
                v-if="validarValores.referencia === 'invalido'"
                class="mensaje-invalido"
              >
                Indica la referencia
              </p>
            </div>
            <div class="col-12">
              <mdb-input
                v-model.trim="pago.observacionCliente"
                type="textarea"
                label="Observacion del pago (opcional)"
                :rows="2"
                class="my-2"
                outline
              />
            </div>
            <div v-if="esEditar" class="col-12 text-center mt-2">
              <mdb-btn
                flat
                dark-waves
                icon="plus"
                class="btn-bordeado my-0 py-2 px-3"
                @click="modalEditarArchivo=true"
              >
                Fotos
              </mdb-btn>
            </div>
            <div v-else class="col-12 my-2">
              <div class="outline-input-file" data-size="sm">
                <label for="input-file-images" class="label-file">
                  <font-awesome-icon icon="upload" class="mr-1" />
                  Elegir comprobante
                </label>
                <span class="contenido">
                  {{
                    comprobante && comprobante.length
                      ? comprobante.length > 1
                        ? comprobante.length + " archivos"
                        : comprobante.length + " archivo"
                      : "No se eligió archivo"
                  }}
                </span>
                <input
                  id="input-file-images"
                  type="file"
                  multiple
                  accept="application/pdf,image/*"
                  class="custom-file-input"
                  @change="comprobantePago"
                />
                <mdb-btn
                  v-if="comprobantePago.length > 0"
                  flat
                  dark-waves
                  icon="times"
                  class="file-button m-0 p-2"
                  title="Eliminar archivos seleccionadas"
                  @click="comprobante = []"
                />
              </div>
            </div>
            <mdb-btn
              color="primario"
              :icon="botonDeshabilitado ? 'circle-notch' : botonPrincipal.icono"
              :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
              :disabled="botonDeshabilitado"
              class="col-auto mt-4"
              @click="submitPago"
            >
              {{ botonPrincipal.texto }}
            </mdb-btn>
          </form>
          <!-- Descomentar el div.comprobantes cuando se realize la funcionalidad de los comprobantes-->
          <!-- <div class="comprobantes">
            <header>Comprobantes</header> -->
            <!-- Asignar el evento @click Eliminar en el div -->
            <!-- <div
              class="chip eliminar-onClick color-terciario"
              title="Haz clic para eliminar el comprobante"
            >
              Documento.pdf
              <mdb-btn
                class="m-0 ml-1 p-1 pb-2"
                dark-waves
                flat
                icon="trash"
                size="sm"
                title="Eliminar comprobante"
              />
            </div>
          </div> -->
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ModalSubirArchivo
      :mostrar-modal="modalEditarArchivo"
      :grafoId="pago.id"
      :soloImagenes="false"
      titulo="Subir comprobantes"
      archivo="comprobantes"
      entidadFoto="Pago"
      @cerrar="modalEditarArchivo = false"
      @alerta="mensajeAlerta($event)"
    />
  </section>
</template>

<script>
import mediosGql from '@/graphql/medios.gql'
import ModalSubirArchivo from '@/components/ModalSubirArchivo.vue'
import { regExpComprobante, EstatusPago } from '@/constantes/pagos.js'
import { capitalizarPrimeraLetra } from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'
export default {
  name: 'FormularioPago',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ModalSubirArchivo
  },
  props: {
    mostrarModalPago: {
      type: Boolean,
      required: true
    },
    esEditar: {
      type: Boolean,
      required: false
    },
    titulo: {
      type: String,
      required: true
    },
    clienteEspecificado: {
      type: Object,
      required: false,
      default: () => {}
    },
    pagoAsignar: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      botonDeshabilitado: false,
      pago: {
        referencia: '',
        observacionCliente: '',
        fecha: {},
        cliente: {}
      },
      medio: {},
      validarValores: {},
      comprobante: [],
      modalEditarArchivo: false
    }
  },
  watch: {
    esEditar: function () {
      this.esEditar &&
        Object.assign(this.pago, JSON.parse(JSON.stringify(this.pagoAsignar))) &&
        Object.assign(this.medio, JSON.parse(JSON.stringify(this.pagoAsignar.medio)))
    }
  },
  methods: {
    cerrarModal () {
      this.$emit('editar', false)
      this.$emit('cerrar', false)
      this.limpiarCamposForm()
    },
    mensajeAlerta (mensaje) {
      this.$emit('alertaMsg', mensaje)
      this.$emit('alerta', mensaje)
    },
    limpiarCamposForm () {
      this.botonDeshabilitado = false
      this.pago = { referencia: '', observacionCliente: '', fecha: {}, cliente: {} }
      this.medio = {}
      this.validarValores = {}
      this.comprobante = []
    },
    comprobantePago ({ target }) {
      if (!target.files.length) return
      target.files.forEach((file) => {
        const result = regExpComprobante.test(file.name)
        result
          ? this.comprobante.push(file)
          : this.$emit('alertaMsg', {
            contenido: 'El archivo no es es de tipo imagen o PDF',
            tipo: 'advertencia'
          })
      })
    },
    submitPago () {
      if (this.clienteEspecificado) {
        this.pago.cliente = this.clienteEspecificado
      }
      if (this.pago.observacionCliente && this.pago.observacionCliente.length) {
        this.pago.observacionCliente = capitalizarPrimeraLetra(this.pago.observacionCliente)
      }
      this.pago.estatus = Object.keys(EstatusPago)[0]
      this.validarValores = {
        fecha: this.pago.fecha.formatted ? 'valido' : 'invalido',
        monto: this.pago.monto ? 'valido' : 'invalido',
        referencia: this.pago.referencia ? 'valido' : 'invalido',
        cliente: this.pago.cliente?.id?.length ? 'valido' : 'invalido',
        medio: this.medio?.id?.length ? 'valido' : 'invalido'
      }
      if (Object.values(this.validarValores).includes('invalido')) {
        this.$emit('alerta', {
          contenido: 'Todos los campos son requeridos',
          tipo: 'error'
        })
        this.botonDeshabilitado = false
        return
      }
      if (this.esEditar) {
        delete this.pago.cliente
        delete this.pago.fecha.__typename
        const { ...rest } = this.pago
        delete rest.medio
        delete rest.__typename
        const pagoEditar = {
          pago: rest,
          medioId: this.medio
        }
        delete pagoEditar.__typename
        this.$emit('editarPago', pagoEditar)
        this.limpiarCamposForm()
        this.cerrarModal()
        return
      }
      if (!this.clienteEspecificado && !Object.keys(this.pago.cliente).length) {
        this.$emit('clienteError')
        if (!this.clienteEspecificado) {
          Object.assign(this.validarValores, {
            cliente: this.pago.cliente.nombre &&
              this.pago.cliente.nombre.length ? 'valido' : 'invalido'
          })
        }
        this.botonDeshabilitado = false
        return
      }
      const pagoCrear = {
        pago: this.pago,
        medioId: this.medio,
        comprobante: this.comprobante
      }
      this.$emit('crear', pagoCrear)
      this.limpiarCamposForm()
      this.$emit('cerrar', false)
    }
  },
  computed: {
    botonPrincipal () {
      return this.esEditar
        ? { icono: 'sync', texto: 'Actualizar' }
        : { icono: 'check', texto: 'Agregar' }
    }
  },
  apollo: {
    medios () {
      return {
        query: mediosGql,
        update: (data) => data.Medio,
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* Transiciones entre los mensajes de .descripcion */
.transition-entre-mensajes-enter-active {animation-name: fadeIn;}
.transition-entre-mensajes-leave-active {animation-name: fadeOut;}
.transition-entre-mensajes-enter-active,
.transition-entre-mensajes-leave-active {animation-duration: .25s;}

.descripcion {
  .mensaje,
  .no-medio {
    border-radius: 4px;
    font-size: .9rem;
    margin-bottom: .8rem;
    padding: 1rem;
  }

  .mensaje {
    background-color: rgba(61, 73, 195,.04);
    border-left: 3px solid $terciario-sombra;

    header {font-size: 1rem;}
    pre {
      color: inherit;
      margin-bottom: 0;
      overflow-wrap: break-word;
      white-space: break-spaces;
      word-break: break-word;
    }
  }
  .no-medio {
    align-items: center;
    border: 2px dashed $gris-tr-borde;
    color: $gris-placeholder;
    display: flex;
    font-style: italic;
    user-select: none;

    svg {
      height: 25px;
      margin-right: .9rem;
      width: 25px;
    }
  }
}

.descripcion .mensaje,
.comprobantes header {color: $terciario-sombra;}

.descripcion .mensaje header,
.comprobantes header {font-weight: 500;}
.comprobantes header {margin-bottom: .5rem;}

// Viewport > 992px
.contenedor-formulario {
  @media screen and (min-width: 992px) {
    display: grid;
    gap: .5rem .8rem;
    grid-template-areas:
      "formulario descripcion"
      "formulario comprobantes";
    grid-template-columns: 1fr 0.6fr;
    grid-template-rows: 1fr 1fr;

    .descripcion,
    .comprobantes {
      max-height: 182px;
      overflow: hidden auto;
    }

    .descripcion {
      grid-area: descripcion;

      .mensaje,
      .no-medio {margin-bottom: 0;}

      .no-medio {
        flex-direction: column;
        padding: 1.5rem 1rem;
        text-align: center;

        svg {
          height: 40px;
          margin: 0 0 1rem;
          width: 40px;
        }
      }
    }
    .formulario { grid-area: formulario; }
    .comprobantes {
      grid-area: comprobantes;
      position: relative;

      header {
        background-color: $claro;
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }
}

.campo {
  margin-bottom: 1rem;

  &.invalido > .mensaje-invalido {
    &.prepend {
      left: 47px;
    }
    &.datalist-title {
      top: 64px;
    }
  }
}
</style>
