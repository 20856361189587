<template>
  <mdb-modal centered elegant :show="mostrarModal" @close="cerrarModal">
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo && titulo }} </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="outline-input-file" data-size="sm">
            <label for="input-file-images" class="label-file">
              <font-awesome-icon icon="upload" class="mr-1" />
              Elegir {{ archivo}}
            </label>
            <span class="contenido">
              {{
                archivos && archivos.length
                ? archivos.length > 1
                  ?  archivos.length + ' archivos'
                  : archivos.length + ' archivo'
                : `No se eligió archivo`
              }}
            </span>
            <input
              id="input-file-images"
              type="file"
              multiple
              :accept="soloImagenes ? 'image/*' :'application/pdf,image/*' "
              class="custom-file-input"
              @change="handleInputChange"
            />
            <mdb-btn
              v-if="archivos.length > 0"
              flat
              dark-waves
              icon="times"
              class="file-button m-0 p-2"
              :title="soloImagenes ? 'Eliminar fotos seleccionadas' : 'Eliminar archivos seleccionados'"
              @click="vaciarArchivos"
            />
          </div>
        </div>
        <div class="col-auto">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitadoComputed ||  botonDeshabilitado"
            class="mt-4"
            @click="agregarArchivos"
          >
            Agregar
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import fotoCrearGql from '@/graphql/fotoCrear.gql'
import { regExpFoto } from '@/constantes/paquetes.js'
import { regExpComprobante } from '@/constantes/pagos.js'

import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
export default {
  name: 'ModalSubirArchivo',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    grafoId: {
      type: String,
      required: false
    },
    titulo: {
      type: String,
      required: false,
      default: 'Agregar fotos al paquete'
    },
    archivo: {
      type: String,
      required: false,
      default: 'archivo'
    },
    soloImagenes: {
      type: Boolean,
      required: false,
      default: true
    },
    entidadFoto: {
      type: String,
      required: true,
      default: 'Pago'
    }
  },
  data () {
    return {
      botonDeshabilitado: false,
      archivos: []
    }
  },
  computed: {
    botonDeshabilitadoComputed () {
      return !this.archivos.length
    }
  },
  methods: {
    cerrarModal () {
      this.$emit('cerrar', false)
    },
    alerta (contenido, tipo) {
      this.$emit('alerta', { contenido, tipo })
    },
    handleInputChange ({ target }) {
      const ext = this.soloImagenes ? regExpFoto : regExpComprobante
      if (!target.files.length) return
      target.files.forEach((file) => {
        const result = ext.test(file.name)
        result ? this.archivos.push(file) : this.alerta(`El archivo que intenta subir no ${this.soloImagenes ? 'es una imagen' : 'corresponde a un formato compatible'}`, 'error')
      })
    },
    vaciarArchivos () {
      this.archivos = []
    },
    agregarArchivos () {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: fotoCrearGql,
          variables: {
            grafoId: this.grafoId,
            fotos: {
              foto: this.archivos
            },
            entidadFoto: this.entidadFoto
          }
        })
        .then(() => {
          this.botonDeshabilitado = false
          this.cerrarModal()
          this.archivos = []
          this.alerta(`${this.soloImagenes ? 'Imagenes enviadas' : 'Archivos subidos'}  correctamente`, 'correcto')
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.cerrarModal()
          this.archivos = []
          this.alerta('Ha ocurrido un error subiendo los archivos', 'error')
        })
    }
  }
}
</script>

<style>
</style>
