const EstatusPago = {
  PorConfirmar: 'Por confirmar',
  Confirmado: 'Confirmado',
  Rechazado: 'Rechazado'
}

const EstatusPagoIconos = {
  PorConfirmar: 'clock',
  Confirmado: 'check-circle',
  Rechazado: 'times-circle'
}

const EstatusPagoIconoColor = {
  PorConfirmar: 'text-muted',
  Confirmado: 'texto-exitoso',
  Rechazado: 'texto-peligro'
}

const regExpComprobante = /\.(jpg|jpeg|png|pdf)$/i

const regExpPdf = /^[a-z0-9_()-[\]]+\.pdf$/i

export {
  EstatusPago,
  EstatusPagoIconos,
  EstatusPagoIconoColor,
  regExpComprobante,
  regExpPdf
}
